<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal fade custom-fields show" id="new-pipeline-template" style="display: block;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("edit job pipeline") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    @click.prevent="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label>{{ $t(`Select A Template`) }}</label>
                                </div>
                                <div class="select-option">
                                    <select v-model="selectedTemplate" @change="selectTemplate">
                                        <option value="">{{ $t(`Select A Template`) }}</option>
                                        <option v-for="(template, tIndex) in templates" :value="tIndex">{{
                                                template.name
                                            }}
                                        </option>
                                    </select>
                                </div>
                                <div class="error-message" v-text="errors.name"></div>

                            </div>
                            <div class="form-group pipeline-step-list">
                                <label>{{ $t(`Pipeline Steps`) }}</label>
                                <draggable v-model="form.steps">
                                    <div class="input-wrapper pipeline-wrapper draggable" v-for="(step, index) in form.steps">
                                        <input type="text" name="" class="form-control" :value="step.name"
                                               v-if="step.name==='Applied'" style="background:#c6d3fd !important;"
                                              readonly/>
                                        <input type="text" name="" class="form-control" :value="step.name"
                                               v-else readonly/>

                                        <a href="#" class="input-wrapper-append" v-if="!step.is_fixed"
                                           @click.prevent="removeStep(step.id, index)">
                                            <i class="eicon e-delete"></i>
                                        </a>
                                    </div>
                                </draggable>
                                <div class="input-wrapper pipeline-wrapper" style="margin-top: 10px;">
                                    <input type="text" name="" class="form-control" :value="$t('Selected')"
                                           style="background:#c6d3fd !important;" disabled/>
                                </div>
                                <div class="input-wrapper pipeline-wrapper">
                                    <input type="text" name="" class="form-control" :value="$t('Rejected')"
                                           style="background: #c6d3fd !important;" disabled/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12 d-flex justify-content-between">
                                    <label>{{ $t(`New Pipeline Step Name`) }}</label>
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="remote_interview" @change="remoteInterview()"/>
                                        <span>{{ $t('Add `Remote Interview`') }}</span>
                                    </label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" name="" v-model.trim="newStep"
                                           v-bind:class="{ 'has-error': errors.step.length }" class="form-control"
                                           :placeholder="$t('New step title')"/>
                                    <div class="error-message" v-text="errors.step"></div>

                                </div>
                                <div class="col-md-4">
                                    <button class="button semi-button-info w-100" @click.prevent="addNewStep">
                                        {{ $t(`Add New`) }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button class="button semi-button-info" data-dismiss="modal" @click.prevent="close">
                                {{ $t(`Back`) }}
                            </button>
                            <submit-button :click="savePipeline" :loading="isLoading">{{ $t(`Save & Continue`) }}
                            </submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import client from "../../../app/api/Client";
import {DEFAULT_PIPELINE} from "../../../constants/enums";
import {mapState} from "vuex";
import {EventBus} from "../../../extra/event-bus";
import {PIPELINE_UPDATED} from "../../../constants/events";

export default {
    components: {
        draggable
    },
    props: [
        'pipeline',
        'job'
    ],
    data() {
        return {
            form: {
                steps: [],
            },
            errors: {
                step: ''
            },
            newStep: '',
            templates: [],
            selectedTemplate: '',
            isLoading: false,
            remote_interview: false
        }

    },
    computed: {
        ...mapState(['company']),
    },
    methods: {
        async removeStep(pipelineId, index) {
            if (pipelineId) {
                let selectedPipeline = this.pipeline.find(pipeline => pipeline.id === pipelineId);
                if (selectedPipeline && selectedPipeline.count > 0) {
                    let confirm = await this.$dialog.confirm({
                        title: this.$t(`Confirmation`),
                        body: this.$t(`There are candidate(s) in this step. If you delete this step some candidates' will be be moved to 'Applied' step. Are you sure you want to make this change?`)
                    });
                    if (confirm) {
                        await this.removeFormStep(pipelineId);
                    } else {
                        return false;
                    }
                } else {
                    await this.removeFormStep(pipelineId);
                }
            } else {
                this.form.steps = this.form.steps.filter((step, ind) => ind !== index);

            }
        },
        removeFormStep(pipelineId) {
            this.form.steps = this.form.steps.filter(step => step.id !== pipelineId);
        },
        savePipeline() {
            this.isLoading = true;
            client().post(`/job/${this.job}/pipeline/update`, this.form).then(({data: {data, message}}) => {
                this.$toast.success(message);
                this.$emit('pipelineUpdated', {data});
                EventBus.$emit(PIPELINE_UPDATED);
            }).catch(({response: {data, status}}) => {
                this.$toast.error(data.message);
                if (status === 480 || status === 490) {
                    this.$emit('closeUpdateModal');
                }
            }).finally(res => {
                this.isLoading = false;
            });
        },
        isUniqueStep(newStep) {
            let tempSteps = [];
            Object.assign(tempSteps, this.form.steps);
            tempSteps.push({
                "id": null,
                "name": 'Selected',
                "order": '',
                "default": false,
                "is_fixed": false
            });
            tempSteps.push({
                "id": null,
                "name": 'Rejected',
                "order": '',
                "default": false,
                "is_fixed": false
            });
            let result = tempSteps.find(step => step.name.trim().toLowerCase() === newStep.trim().toLowerCase());
            return !result;
        },
        addNewStep() {
            if (this.newStep == '') {
                this.errors.step = this.$t(`Please write the step name`);
            } else if (this.newStep.length < 4) {
                this.errors.step = this.$t(`Step title is at least 4 characters`);

            } else if (this.newStep != '' && this.newStep.length >=4) {

                if (this.isUniqueStep(this.newStep)) {
                    this.form.steps.push({
                        "id": null,
                        "name": this.newStep,
                        "order": this.form.steps.length,
                        "default": false,
                        "is_fixed": false
                    });
                    this.newStep = '';
                    this.errors.step = '';
                }else {
                    this.errors.step = this.$t('This step title already exists. Please enter a unique step title.');
                }

            } else {
                this.errors.step = this.$t(`Please write the step name`);
            }
        },
        close() {
            this.$emit('closeUpdateModal');
        },
        reSet() {
            this.form.name = '';
            this.form.steps = [];
            this.errors.name = '';
            this.errors.step = '';
        },
        async getTemplates() {
            let {data: {data}} = await client().get('company/setting/pipeline?mode=job');
            this.templates = data;
        },
        async selectTemplate() {
            if (this.selectedTemplate || this.selectedTemplate === 0) {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`There are candidate(s) in some steps. If you change pipeline template some candidates' will be be moved to 'Applied' step. Are you sure you want to make this change?`)
                });
                if (confirm) {
                    await this.changeTemplate();
                }
            } else {
                await this.changeTemplate();
            }

        },
        changeTemplate() {
            let selectedTempleteIndex = this.selectedTemplate;
            let template = this.templates[selectedTempleteIndex] || null;
            if (!_.isNull(template)) {
                let remoteInterview = _.filter(this.form.steps, s => s.id == DEFAULT_PIPELINE.REMOTE_INTERVIEW);
                if (remoteInterview.length > 0) {
                    this.remote_interview = true;
                    this.remoteInterview();
                }
                this.form.steps = template.steps;
            }
        },
        remoteInterview() {
            if (this.remote_interview) {
                if (!this.company.package_rule.remote_interview) {
                    this.$toast.error(this.$t(`Remote interview not supported. Please upgrade subscription package.`));
                    this.remote_interview = false;
                    return false;
                }
                client().get('/remote-interview-pipeline').then(({data: {data}}) => {
                    this.form.steps.push(data);
                });
            } else {
                this.form.steps = _.filter(this.form.steps, s => s.id != DEFAULT_PIPELINE.REMOTE_INTERVIEW)
            }


        }

    },
    created() {
        this.getTemplates();
        this.form.steps = this.pipeline.map((d) => {
            let t = _.omit(d, ['applicants', 'count']);
            return t;
        });
        let remoteInterview = _.filter(this.form.steps, s => s.id == DEFAULT_PIPELINE.REMOTE_INTERVIEW);
        if (remoteInterview.length > 0) {
            this.remote_interview = true;
        }

    }
};
</script>
<style>
.dg-backdrop {
    z-index: 1050 !important;
}



</style>

<style scoped>
    .input-wrapper.pipeline-wrapper.draggable .form-control  {
        cursor: ns-resize;
    }
</style>
